/**
 * Color scheme
 */
$theme-colors: (
        "primary": #1da1f2,
        "info": #cdcdcd,
        "dark": #454545,
        "black": #000000,
);

// Import Bootstrap and its default variables
@import '../../../node_modules/bootstrap/scss/bootstrap';

body {
  background-color: black;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  body {
    background-color: white;
  }

  .container-iphone {
    margin: 0 auto;
    padding: 120px 140px 120px 140px;
    width: 707px;
    height: 1000px;
    background-image: url('../img/iphone-6-gold.jpg');
  }
}

/**
 * Header
 */
.header {
  @extend .py-2;
}

.header img {
  @extend .img-fluid;
  @extend .rounded-circle;
}

.header h1 {
  font-size: 18px;
  font-weight: bold;
}

/**
 * Loading
 */
.loading > div > div {
  @extend .text-white;
  @extend .font-weight-bold;
  @extend .text-center;

  font-size: 36px;
  margin-top: 30%;
}

/**
 * Login
 */
.login {
  @extend .px-4;
  @extend .py-4;
}

.login-content > div {
  @extend .mt-5;
}

.login-content h1 {
  @extend .text-light;

  font-size: 28px;
  font-weight: bold;
}

.login-content p {
  @extend .text-info;
}

.login button {
  @extend .btn;
  @extend .btn-primary;
  @extend .p-3;

  width: 100%;
}

/**
 * Home
 */
.home .header {
  @extend .text-white;
  @extend .border-light;
  @extend .border-bottom;
  @extend .bg-black;
}

.home .btn-tweet {
  @extend .text-right;

  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-right: 0.5rem;
}

.home .btn-tweet > a {
  @extend .btn;
  @extend .btn-primary;
  @extend .rounded-circle;
  @extend .p-3;
}

.home .footer {
  @extend .text-center;
  @extend .border-light;
  @extend .border-top;
  @extend .bg-black;
}

/**
 * Compose
 */
.compose {
  @extend .container;

  .compose-header {
    @extend .row;
    @extend .py-2;
    @extend .border-bottom;

    .compose-back {
      @extend .col-4;
      //@extend .m-1;

      > a {
        @extend .m-1;
      }
    }

    .compose-tweet {
      @extend .col-4;
      @extend .text-center;

      > button {
        @extend .btn;
        @extend .btn-primary;
        @extend .m-1;
      }
    }
  }

  .compose-body {
    @extend .row;
    @extend .mt-3;

    .compose-photo {
      @extend .col-2;
    }

    .compose-message {
      @extend .col-10;
      @extend .text-white;
      @extend .font-weight-bold;

      > textarea {
        width: 300px;
        background-color: black;
        color: #fff;

        border: none;
        overflow: auto;
        outline: none;

        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;

        resize: none;
      }
    }
  }
}

.tweet {
  @extend .row;

  > div {
    @extend .col-12;

    > div {
      @extend .row;
      @extend .border-bottom;
      @extend .border-dark;

      > .tweet-image {
        @extend .col-3;

        > img {
          @extend .img-fluid;
          @extend .rounded-circle;
          @extend .p-2;
        }
      }

      > .tweet-text {
        @extend .col-9;
        @extend .p-2;
        @extend .text-white;

        > h6 > span {
          @extend .text-dark;
        }
      }
    }
  }
}

/**
 *
 */
.btn-icon {
  margin: 1.2rem !important;
}

.tooltip-danger.bs-tooltip-top > .arrow:before {
  border-top-color: $danger;
}

.tooltip-danger.bs-tooltip-right > .arrow:before {
  border-right-color: $danger;
}

.tooltip-danger.bs-tooltip-bottom > .arrow:before {
  border-bottom-color: $danger;
}

.tooltip-danger.bs-tooltip-left > .arrow:before {
  border-left-color: $danger;
}

.tooltip-danger > .tooltip-inner {
  background-color: $danger;
}

.btn-primary {
  border-radius: 9999px;
}
